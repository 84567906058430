const {
	//BIODATA
	VITE_FIRST_NAME_FIELD_ID,
	VITE_LAST_NAME_FIELD_ID,
	VITE_MOTHER_DEATH_VERIFICATION_METHOD_FIELD_ID,
	VITE_WHEN_DID_THE_MOTHER_LEAVE_HER_CHILDREN_FIELD_ID,
	VITE_FATHER_DEATH_VERIFICATION_METHOD_FIELD_ID,
	VITE_MOTHER_DOD_FIELD_ID,
	VITE_FATHER_CAUSE_OF_DEATH_FIELD_ID,
	VITE_MOTHER_CAUSE_OF_DEATH_FIELD_ID,
	VITE_FATHER_DOD_FIELD_ID,
	VITE_WHY_DID_THE_MOTHER_LEAVE_HER_CHILDREN_FIELD_ID,
	VITE_FATHER_FULL_NAME_IN_LOCAL_LANGUAGE_FIELD_ID,
	VITE_FATHER_FULL_NAME_FIELD_ID,
	VITE_GUARDIAN_ID_OR_PASSPORT_NUMBER_FIELD_ID,
	VITE_NATIONALITY_FIELD_ID,
	VITE_GUARDIAN_RELATION_TO_ORPHAN_FIELD_ID,
	VITE_GENDER_Field_ID,
	VITE_GUARDIAN_NATIONALITY_FIELD_ID,
	VITE_ORPHAN_DOB_FIELD_ID,
	VITE_ORPHAN_FULL_NAME_IN_LOCAL_LANGUAGE_FIELD_ID,
	VITE_GUARDIAN_DOB_FIELD_ID,
	VITE_GUARDIAN_FULL_NAME_IN_LOCAL_LANGUAGE_FIELD_ID,
	VITE_GUARDIAN_FULL_NAME_FIELD_ID,
	VITE_MIDDLE_NAME_FIELD_ID,
	VITE_MOTHER_FULL_NAME_FIELD_ID,

	
	//ID ATTACHMENTS
	VITE_GUARDIAN_ID_FIELD_ID,
	VITE_CHILD_ID_FIELD_ID,
	VITE_FATHER_DEATH_CERTIFICATE_FIELD_ID,
	VITE_MOTHER_DEATH_CERTIFICATE_FIELD_ID,
	VITE_UNITED_NATIONS_CERTIFICATE_UMAM_FOR_THE_SYRIAN_ONLY_FIELD_ID,
	VITE_DISABLED_CARD_FIELD_ID
} = import.meta.env
const oneTimeFields = [
	//BIODATA
	parseInt(VITE_FIRST_NAME_FIELD_ID),
	parseInt(VITE_LAST_NAME_FIELD_ID),
	parseInt(VITE_MOTHER_DEATH_VERIFICATION_METHOD_FIELD_ID),
	parseInt(VITE_WHEN_DID_THE_MOTHER_LEAVE_HER_CHILDREN_FIELD_ID),
	parseInt(VITE_FATHER_DEATH_VERIFICATION_METHOD_FIELD_ID),
	parseInt(VITE_MOTHER_DOD_FIELD_ID),
	parseInt(VITE_FATHER_CAUSE_OF_DEATH_FIELD_ID),
	parseInt(VITE_MOTHER_CAUSE_OF_DEATH_FIELD_ID),
	parseInt(VITE_FATHER_DOD_FIELD_ID),
	parseInt(VITE_WHY_DID_THE_MOTHER_LEAVE_HER_CHILDREN_FIELD_ID),
	parseInt(VITE_FATHER_FULL_NAME_IN_LOCAL_LANGUAGE_FIELD_ID),
	parseInt(VITE_FATHER_FULL_NAME_FIELD_ID),
	parseInt(VITE_GUARDIAN_ID_OR_PASSPORT_NUMBER_FIELD_ID),
	parseInt(VITE_NATIONALITY_FIELD_ID),
	parseInt(VITE_GUARDIAN_RELATION_TO_ORPHAN_FIELD_ID),
	parseInt(VITE_GENDER_Field_ID),
	parseInt(VITE_GUARDIAN_NATIONALITY_FIELD_ID),
	parseInt(VITE_ORPHAN_DOB_FIELD_ID),
	parseInt(VITE_ORPHAN_FULL_NAME_IN_LOCAL_LANGUAGE_FIELD_ID),
	parseInt(VITE_GUARDIAN_DOB_FIELD_ID),
	parseInt(VITE_GUARDIAN_FULL_NAME_IN_LOCAL_LANGUAGE_FIELD_ID),
	parseInt(VITE_GUARDIAN_FULL_NAME_FIELD_ID),
	parseInt(VITE_MIDDLE_NAME_FIELD_ID),
	parseInt(VITE_MOTHER_FULL_NAME_FIELD_ID),
	
	//ID ATTACHMENTS
	parseInt(VITE_GUARDIAN_ID_FIELD_ID),
	parseInt(VITE_CHILD_ID_FIELD_ID),
	parseInt(VITE_FATHER_DEATH_CERTIFICATE_FIELD_ID),
	parseInt(VITE_MOTHER_DEATH_CERTIFICATE_FIELD_ID),
	parseInt(VITE_UNITED_NATIONS_CERTIFICATE_UMAM_FOR_THE_SYRIAN_ONLY_FIELD_ID),
	parseInt(VITE_DISABLED_CARD_FIELD_ID)
]
export default oneTimeFields
